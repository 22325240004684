@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
.hexagon {
    position: relative;
    width: 9rem;
    height: 10.2rem;
    transform: rotate(270deg);
    
  }

  .hexagon-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #e04e5e;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .hexaText{
    transform:rotate(-270deg);
    text-align: center;
    user-select: none;
    color: white;
    font-size: large;
    font-weight: bolder;
  }
  .hexaClick{
    transform:rotate(-270deg);
    user-select: none;
    color: white;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    z-index: 100000;
  }
.hexagrid{
    margin-bottom: 6rem;
    margin-top: 6rem;
}
.grid{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-inline: 8rem;
    
    /*filter: blur(8px);
    -webkit-filter: blur(8px);*/
}
.headerText{
    color: #36454F;
    font-size: 6rem;
    font-weight: 100;
}
.servicesContainer{
    /*background: linear-gradient(#633236,#466895);*/
    background-color:#D3D3D3 ;
}
.detailSection{
    text-align: left;
    color:white;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-block: 4rem;
    padding: 1rem;
    border-radius: 20px;
}
.detailTextContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.detailText{
    font-size: 1.2rem;
    font-family: "Fredoka", sans-serif;
    line-height: 2.7rem;
    margin-top: 1.5rem;
}
.detailImg{
    border-radius: 20%;
    border-color: #633236;
    border-width: 5px;
    margin-inline: 3rem;
}
@media (max-width: 480px){
  .hexagon {
    position: relative;
    width: 5rem;
    height: 5.67rem;
    transform: rotate(270deg);
  }
.hexagrid{
    margin-bottom: 6rem;
    margin-top: 6rem;
}
.headerText{
  color: #36454F;
  font-size: 4rem;
  font-weight: 100;
}
.hexaText{
    transform:rotate(-270deg);
    text-align: center;
    user-select: none;
    color: white;
    font-weight: bold;
    font-size: small;
  }
}