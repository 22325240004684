@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');

.App {
  text-align: center;
  font-family: 'Amatic SC', cursive;

}

::-webkit-scrollbar {
  width: 1.2vh;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(70,61,90);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(242,242,242, 0.959);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(242,242,242, 0.911);
  border-radius: 3px;
}
