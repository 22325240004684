
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
.container{
    min-height: 50rem;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.5rem;
    /*background-color: rgba(219,43,57,0.3);
    background: linear-gradient(#e57976,#633236);*/
    background-color: #D3D3D3;
    background-repeat:initial;
    padding-bottom: 6rem;
    font-family: 'Fredoka', sans-serif;
}
.textContainer{
    margin: 2rem;
    text-align: left;
    min-width: 10rem;
    min-height: 25rem;
    padding: 2rem;
    color: #36454F;
    /* background-color: wheat;
    border-radius: 1rem; */
}

.imgContainer{
    height: 25rem;
}

.profilePicture{
    height: 25rem;
    border-radius: 20px;
}