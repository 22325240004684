@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
.contact{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 6rem;
  padding-top: 6rem;
  min-height: 58.3rem;
  font-size: 1.5rem;
  /*background:linear-gradient(#466895, #bcd6bb);*/
  background-color: #D3D3D3;
}

.left-form{
  min-width: 10rem;
  width: 35rem;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #36454F;
}

.email-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-family: "Fredoka",sans-serif;

}
.opening-dates-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  font-family: "Fredoka",sans-serif;

}


  .form-container { /*CONTACT FORM*/
    background-color: rgb(99,50,54,0.25) !important;  
    border-radius: 10px !important;
    padding-top: 2rem !important;
    min-width: 10rem;
    width:35rem;
    height: 50rem;
  }
  
  .form-title-1{
    font-size: 3rem;
    font-weight: 500;
  }
  
  .form-title-2{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .my-4{
    margin-top: 10px;
    width: 10rem;
    
  }

  .my-5{
    margin-top: 10px;

  }

  .custom-submit-button {
    background-color: rgb(54,69,79) !important;
    border-color: rgb(54,69,79)!important;
    font-family: 'Amatic SC', cursive;
    font-size: 1.2rem;
    font-weight: 1000;
    padding: 3px;
    height: 2rem;
    width: 4rem;
  }
  
  .custom-submit-button:hover {
    background-color: rgb(54,69,79) !important;
    border-color: rgb(54,69,79) !important;
  }
  
