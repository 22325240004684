

.Home {
    font-size: 10rem;
    justify-content: center;
    padding-top: 5rem;
    height: 52rem;
    display: flex;
    background: linear-gradient(#3b2c49,#D3D3D3);
    background-color: #D3D3D3;
    color: #eee;
    text-shadow: black;
  }
  
  
.er{
  margin-right: 0;
  color: whitesmoke;
}
  
.in{
  margin-right: 0;
  color: whitesmoke;
}

.t{
  margin-right: 0;  
  color: whitesmoke;
}

.e{
  margin-right: 0;
  color: whitesmoke;
}

.s{
  margin-right: 0;
  color: whitesmoke;
}

.animated-sub{
  width: 2rem;
  height: 25rem;
  font-size: 3.5rem;
}

.er-sub{
  margin-left: 1.2rem;
}

.in-sub{
  margin-left: 1.5rem;
}

@media (max-width: 480px) {
	.Home {
    font-size: 6rem;
	}
}
